import { modalController } from '@ionic/core';
import { store } from '@stencil/redux';
import { appAccessDenied, appInstallAppSuccess, appOfflineMigrationComplete, appSync, appSyncSuccess, appTrialExpired, downloadAttachments, downloadMapImagery, downloadMedia, loadApp, reloadApp } from '../store/app/app.actions';
import { getAppOfflineMigrationComplete, getAppsCount, getAuthIsAuth } from '../store/selectors';

import { CoreoApiAppSummary, Unthunk } from '../types';
import { CoreoAPI } from './api.service';

import { joinProjectMutation, projectList, organisationList, projectSummary, organisationDetail } from './coreo/coreo.queries';

export class AppService {

  public static instance: AppService = new AppService();

  appSync: Unthunk<typeof appSync>;
  appSyncSuccess: Unthunk<typeof appSyncSuccess>;
  appInstallAppSuccess: Unthunk<typeof appInstallAppSuccess>;
  appAccessDenied: Unthunk<typeof appAccessDenied>;
  appTrialExpired: Unthunk<typeof appTrialExpired>;
  downloadMedia: Unthunk<typeof downloadMedia>;
  downloadAttachments: Unthunk<typeof downloadAttachments>;
  downloadMapImagery: Unthunk<typeof downloadMapImagery>;
  loadApp: Unthunk<typeof loadApp>;
  reloadApp: Unthunk<typeof reloadApp>;

  constructor() {
    store.mapDispatchToProps(this, {
      appSync,
      appSyncSuccess,
      appInstallAppSuccess,
      appAccessDenied,
      appTrialExpired,
      downloadMedia,
      downloadAttachments,
      downloadMapImagery,
      loadApp,
      reloadApp
    });
  }

  getAppsList = async (): Promise<CoreoApiAppSummary[]> => {
    const result = await CoreoAPI.instance.graphql(projectList, {});

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    return result.data?.projects ?? null;
  }

  getAppSummaryFromAPI = async (id: number): Promise<CoreoApiAppSummary> => {
    const result: any = await CoreoAPI.instance.graphql(projectSummary, { projectId: id });

    if (result.errors) {
      throw new Error(result.error[0].message);
    }

    const { name, imageUrl, description, allowContributors } = result.data?.project;

    return {
      id,
      name,
      icon: imageUrl,
      description,
      allowContributors
    };
  }

  joinProject = async (projectId: number): Promise<string> => {
    const response = await CoreoAPI.instance.graphql(joinProjectMutation, {
      projectId
    });
    if (response?.errors?.length > 0) {
      throw response.errors[0];
    }

    const result = response?.data?.joinProject?.role;
    return result;
  }

  public async offlineMigrationTest(force: boolean = false) {
    const state = store.getState();

    const offlineMigrationComplete = getAppOfflineMigrationComplete(state);

    if (offlineMigrationComplete && !force) {
      return;
    }

    const appsCount = getAppsCount(state);
    const isAuth = getAuthIsAuth(state);

    if (!force && !offlineMigrationComplete && appsCount === 0 && !isAuth) {
      store.getStore().dispatch(appOfflineMigrationComplete());
      return;
    }

    const modal = await modalController.create({
      component: 'app-offline-migration',
      backdropDismiss: false,
      keyboardClose: false
    });
    modal.present();
    return modal;
  }

  getOrganisationList = async (): Promise<{ id: number; name: string }[]> => {
    const result = await CoreoAPI.instance.graphql(organisationList, {});

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }

    const organisations = result.data.organisations;
    organisations.sort((a, b) => a.name.localeCompare(b.name));
    return organisations;
  }

  getOrganisationDetail = async (id: number) => {
    const result = await CoreoAPI.instance.graphql(organisationDetail, { id });
    const org = result.data.organisation;
    return { ...org, ...this.buildTree(org.projects, org.folders) };
  }

  buildTree(projects: any[], folders: any[], folderId: string | null = null) {
    const filteredProjects = projects.filter(p => p.organisationFolderId === folderId);
    const children = folders.filter(f => f.parentId === folderId).map(f => this.buildTree(projects, folders, f.id));
    const folder = folders.find(f => f.id === folderId);
    return { ...folder, projects: filteredProjects, children }
  };

  getParentTree(arr, id) {
    return arr.reduce((found, obj) => {
      if (found) return found;
      if (obj.id === id) return obj;
      if (obj.children) return this.getParentTree(obj.children, id);
      return null;
    }, null);
  }

}
